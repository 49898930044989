<template>
  <v-card
    :width="width"
    outlined
    class="ad-thumb"
    :class="{ 'paused': !active && !(hover||highlight), 'active elevation-4': hover||highlight }"
    @click="select"
    @click.stop
  >
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      top
      height="2"
    />

    <media-player
      :url="url"
      :format="format"
      :title="title"
      :autoplay="autoplay"
    />  
    <div
      class="d-flex justify-space-between align-center"
    >
      <v-card-title 
        class="title pa-3 pl-4 grey--text text--darken-1 text-overline text-caption text-truncate d-inline-block"
      >
        {{ title }}
        <v-chip
          v-if="admin&&(hover||highlight)"
          label
          outlined
          x-small
          color="grey darken-1"
          class="px-1 ml-1"
        >
          {{ id }}
        </v-chip>
      </v-card-title>
      <m-btn-long
        v-if="activatable"
        :icon="view.activate.hover&&active||!view.activate.hover&&!active ? icons.mdiPause : icons.mdiPlay"
        small
        :color="view.activate.hover&&active||!view.activate.hover&&!active ? 'grey' : 'success'"
        :loading="loading"
        :tooltip="activateConfig.tooltip" 
        :duration="activateConfig.duration"
        :press-color="active ? 'grey' : 'success'"
        content-class="btn-active mr-1"
        @hover="activateHover"
        @cancel="activate(false)"
        @press="activate(true)"
      />
    </div>
  </v-card>
</template>

<style>

  .ad-thumb {
    line-height: 0;
    overflow: hidden;
    cursor: pointer;
    will-change: box-shadow, transform, opacity;
    transform: translateY(0);
    transition: box-shadow .15s cubic-bezier(0.25, 0.8, 0.5, 1), transform .15s cubic-bezier(0.25, 0.8, 0.5, 1), opacity .15s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .ad-thumb.paused {
    opacity: .6;
  }
  .list .ad-thumb.active {
    opacity: 1;
    transform: translateY(-4px) !important;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px !important; */
  }
  .ad-thumb .title {
    max-width: 100%;
    min-width: 0;
    font-size: .6875rem !important;
    line-height: 1.25rem;
  }

  .ad-thumb .thumb {
    width: 100%;
  }

  .ad-thumb .v-overlay__content {
    display: flex;
    flex: 0 0 100%;
    align-self: flex-end;
    width: 100%;
  }

  .ad-thumb .v-image__image--preload {
    filter: blur(0);
  }

</style>

<script>
  import { mdiCheck, mdiMenuDown, mdiPlay, mdiPause, mdiDotsVertical, mdiDelete, mdiClock, mdiOpenInNew } from '@mdi/js';
  import { sync } from 'vuex-pathify'
  import services from "@/services";

  export default {
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      updated: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Boolean,
        default: false
      },
      active: {
        type: Number,
        default: 0
      },
      id: {
        type: [Number, String],
        default: null
      },
      title: {
        type: String,
        default: 'Carregando...'
      },
      media: {
        type: Object,
        default: () => {}
      },
      width: {
        type: [Number, String],
        default: 288
      },
      admin: {
        type: Boolean,
        default: true
      },
      hover: {
        type: Boolean,
        default: false
      },
      highlight: {
        type: Boolean,
        default: false
      },
      autoplay: {
        type: Boolean,
        default: true
      },
      activatable: {
        type: Boolean,
        default: true
      },
    },

    data: () => ({
      icons: {
        mdiCheck, mdiMenuDown, mdiPlay, mdiPause, mdiDotsVertical, mdiDelete, mdiClock, mdiOpenInNew
      },
      view: {
        activate: {
          hover: false,
          duration: 2000,
          tooltip: {
            top: true,
            disabled: false,
            text: ''
          }
        }
      }
    }),

    computed: {
      toast: sync('app/toast'),

      url () {
        const url = this.media.url;
        const key = this.refresh ? '?r='+this.$moment().valueOf() : '';
        return !!url ? url + key : null;
      },

      format () {
        const format = _.has(this.media, 'format') ? this.media.format : null;
        return format;
      },

      activateConfig () {
        const config = this.view.activate;
        const active = this.active;
        return _.merge(config, { 
          tooltip: {
            text: this.$t('general.'+(active ? 'pause' : 'activate')),
          }
        })
      },
    },

    watch: {
      hover (b) {
        this.$emit('hover', b ? this.id : null);
      }
    },

    methods: {
      ...services,

      select () {
        if (!this.loading) this.$emit('select', this.id);
      },
      approve () {
        this.$emit('approve', this.id);
      },
      remove () {
        console.log('delete', this.id)
      },
      handleException (error) {
        console.log(error);
      },

      activateHover (b) {
        this.view.activate.hover = b;
      },

      activate(confirm) {
        console.log(confirm)
        if (confirm) {
          console.log("activate", this.id);
          this.$emit("activate", this.id);
          this.toggleToast(
            false,
            '',
            0,
            false
          );
        }else{
          const verb = this.$t('general.'+(active ? 'pause' : 'activate'))
          this.toggleToast(
            true,
            this.$t('general.long_btn_hint', { verb }),
            7000,
            false
          );
        }
      },
    },

    components: {
      MediaPlayer: () => import('@/components/campaigns/MediaPlayer'),
      MBtnLong: () => import('@/components/mBtnLong')
    }
  }
</script>